<script setup lang="ts">
import { IconChevronRight } from '@tabler/icons-vue'

const properties = defineProps({
  imageUrl: {
    type: String,
    required: true
  },
  alt: {
    type: String,
    required: true
  }
})
</script>

<template>
  <div class="header-container">
    <div class="header-image-container">
      <NuxtImg :src="properties.imageUrl" :alt="properties.alt" class="header-image w-100" />
    </div>
    <NuxtLink to="/referenzen#intro" class="header-right-container">
      <span class="header-right-container-text text-h3 text-md-h1">Referenzen</span>

      <div class="header-right-container-icon">
        <IconChevronRight color="white" size="50" stroke-width="2px" />
      </div>
    </NuxtLink>
  </div>
</template>

<style scoped lang="scss">
.header-container {
  display: flex;
  flex-direction: row;
  height: 80vh;
  padding-top: 160px;
}

// eslint-disable-next-line vue-scoped-css/no-unused-selector
.header-container a {
  text-decoration: none;
}

.header-image {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.header-image-container {
  flex-basis: 100%;
}

.header-right-container {
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.header-right-container-text {
  // transform: rotate(90deg);
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-weight: 600;
  color: rgba(var(--v-theme-primary), 1);
}

.header-right-container-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1/1;
  background-color: rgba(var(--v-theme-primary), 1);
}
</style>
