<script setup lang="ts">
import type { MenuItem } from '~/types/menu/MenuItem'

defineComponent({
  name: 'NavigationComponent'
})

const properties = defineProps({
  items: {
    type: Array as () => MenuItem[],
    required: true
  },
  active: {
    type: String,
    default: ''
  }
})
</script>

<template>
  <template v-for="item of properties.items" :key="item.name">
    <NuxtLink :to="item.href" class="ml-8 menu-links">
      <span :class="item.href === properties.active ? 'text-primary' : ''">{{ item.title }}</span>
    </NuxtLink>
  </template>
</template>

<style scoped lang="scss">
.menu-links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  padding: 0;
  margin: 0;
  list-style: none;
  font-weight: 500;
  color: rgba(var(--v-theme-textSecondary), 1);
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: rgba(var(--v-theme-primary), 1);
  }
}
</style>
