<script setup lang="ts">
import type { MenuItem } from '~/types/menu/MenuItem'

const mobileMenu = ref(false)
const headerHeight = ref(160)

const route = useRoute()

const menuItems: MenuItem[] = [
  {
    title: 'Home',
    href: '/',
    visible: true
  },
  {
    title: 'Team',
    href: '/team',
    visible: true
  },
  {
    title: 'Leistungen',
    href: '/leistungen',
    visible: true
  },
  {
    title: 'Referenzen',
    href: '/referenzen',
    visible: true
  }
]

defineComponent({
  name: 'HeaderComponent'
})

onMounted(() => {
  window.addEventListener('scroll', () => {
    headerHeight.value = window.scrollY <= 0 ? 160 : 80
  })
})
</script>
<template>
  <div>
    <div>
      <v-app-bar :height="headerHeight" class="lp-header" flat>
        <v-container class="maxWidth py-sm-4 py-0">
          <v-toolbar class="d-flex align-center bg-white">
            <div>
              <Logo />
            </div>
            <div class="ml-auto d-lg-flex d-none">
              <Navigation :items="menuItems" :active="route.path" />
            </div>
            <div class="hidden-lg-and-up ml-auto mr-4">
              <div
                class="menu-wrapper"
                :class="{ active: mobileMenu }"
                @click.stop="mobileMenu = !mobileMenu"
              >
                <div class="menu-bar one"></div>
                <div class="menu-bar two"></div>
                <div class="menu-bar three"></div>
              </div>
            </div>
          </v-toolbar>
        </v-container>
      </v-app-bar>
      <div v-if="mobileMenu" class="mobile-menu-container" :style="`margin-top:${headerHeight}px;`">
        <template v-for="item of menuItems" :key="item.title">
          <div v-if="item.visible" class="mobile-menu-items-container">
            <a
              :href="item.href"
              class="mobile-menu-item text-body text-h5 mb-6"
              :class="{ 'text-primary': item.href === route.path }"
              >{{ item.title }}</a
            >
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.bg-white {
  background-color: #fff;
}

.mobile-menu-container {
  position: fixed;
  z-index: 1000;
}

.mobile-menu-items-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  width: 100vw;
}

.mobile-menu-item {
  text-align: center;
  color: #000;
  text-decoration: none;
  transition: all 0.3s;
}

.menu-wrapper {
  width: 26px;
  height: 26px;
  cursor: pointer;
  position: relative;
}

.menu-bar {
  position: absolute;
  width: 100%;
  height: 2px;
  background: #000;
  left: 0%;
}

// eslint-disable-next-line vue-scoped-css/no-unused-selector
.menu-wrapper:hover .menu-bar.active {
  animation: none;
}
.active .one {
  top: 50%;
  left: 0%;
  transform: rotate(45deg);
}

.active .two {
  opacity: 0;
  transition: all 0.3s;
}

.active .three {
  top: 50%;
  left: 0%;
  transform: rotate(-45deg);
}

.one {
  top: 5px;
  animation-delay: 0.1s;
  transition: all 0.3s;
}

.two {
  top: 12px;
  opacity: 1;
  transition: all 0.3s;
}

.three {
  top: 19px;
  transition: all 0.3s;
}
</style>
