<script>
export default {
  name: 'MainLogo'
}
</script>
<template>
  <div class="logo">
    <NuxtLink to="/">
      <div class="d-flex align-center">
        <NuxtImg class="mr-4" src="/logo.svg" alt="Logo" height="35px" />
        <span class="text-body text-lg-h4 font-weight-semibold">johnsen +partner architekten</span>
      </div>
    </NuxtLink>
  </div>
</template>

<style scoped lang="scss">
// eslint-disable-next-line vue-scoped-css/no-unused-selector
.logo > a {
  text-decoration: none;
  color: rgba(var(--v-theme-textSecondary));
}
</style>
